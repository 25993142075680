
import Vue from 'vue';
import StatusItem from './statusItem.vue';
import WhatsNewItem from './statusWNItem.vue';
import statusCheck from '../../mixins/statusCheck';
import { StatusAlert, StatusAlerts, WhatsNew } from '../../types/status';
import utils from '../../util';

export default Vue.extend({
  name: 'statusAdmin',
  components: {
    StatusItem,
    WhatsNewItem,
  },
  data(): {
    status: StatusAlerts;
    selectedAlert: StatusAlert;
    showAlerts: boolean;
    showWhatsNew: boolean;
  } {
    return {
      showAlerts: true,
      showWhatsNew: false,
      selectedAlert: {
        active: false,
        dismissable: false,
        location: '',
        title: '',
        message: '',
        icon: 'warning',
        color: 'red',
        start: {
          date: '',
          time: '',
          utc: '',
        },
        end: {
          date: '',
          time: '',
          utc: '',
        },
        timezone: '',
      },
      status: {
        alerts: [],
        whatsnew: {
          active: false,
          start: {
            date: '',
            time: '',
            utc: '',
          },
          end: {
            date: '',
            time: '',
            utc: '',
          },
          timezone: '',
        },
        version: 0,
      },
    };
  },
  mixins: [statusCheck],
  mounted() {
    this.getDashboardStatus();
  },
  computed: {
    groupedAlerts(): Array<Array<StatusAlert>> {
      if (!this.status?.alerts) return [];
      // sort items by location friendly name
      const sortedGroups = [...this.status.alerts].sort((a: StatusAlert, b: StatusAlert) =>
        utils.getTacticName(a.location).localeCompare(utils.getTacticName(b.location)),
      );
      // group items by location
      return Object.values(
        sortedGroups.reduce((acc: Array<StatusAlert>, item: StatusAlert) => {
          acc[item.location] = acc[item.location] || [];
          acc[item.location].push(item);
          return acc;
        }, {}),
      );
    },
    activeCount(): number {
      const amount = this.status.alerts.filter((alert: StatusAlert) => this.checkActiveById(alert.id));
      return amount.length;
    },
    inactiveCount(): number {
      const amount = this.status.alerts.filter((alert: StatusAlert) => !this.checkActiveById(alert.id));
      return amount.length;
    },
    loading(): string {
      return this.$store.state?.status?.loading;
    },
    currentWhatsNew(): WhatsNew {
      return this.status?.whatsnew;
    },
  },
  methods: {
    getTacticName(tab: string): string {
      return utils.getTacticName(tab) || 'Unknown';
    },
    selectAlert(item: StatusAlert): void {
      this.selectedAlert = item;
      this.showWhatsNew = false;
      this.showAlerts = true;
    },
    addNew(): void {
      this.showWhatsNew = false;
      this.showAlerts = true;
      this.selectedAlert = {
        name: '',
        active: false,
        dismissable: false,
        location: '',
        title: '',
        message: '',
        icon: 'warning',
        color: 'red',
        start: {
          date: '',
          time: '',
          utc: '',
        },
        end: {
          date: '',
          time: '',
          utc: '',
        },
        timezone: '',
      };
    },
    async getDashboardStatus(): Promise<void> {
      this.$store.dispatch('status/setDashboardLoading', true);
      await this.$store
        .dispatch('status/getDashboardStatus')
        .then(data => {
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getDashboardStatus bad response', data);
            return;
          }
          if (data.error) {
            // eslint-disable-next-line no-console
            console.log('getDashboardStatus error response', data.error);
            return;
          }
          if (data.empty) {
            // eslint-disable-next-line no-console
            console.log('getDashboardStatus empty response', data.error);
            if (data.version) this.status.version = data.version;
            this.$store.dispatch('status/setDashboardLoading', false);
            return;
          }
          this.status = { ...data };
          this.$store.dispatch('status/setDashboardLoading', false);
        })
        .catch((error: Error) => {
          this.$store.dispatch('status/setDashboardLoading', false);
          // eslint-disable-next-line no-console
          console.error('getDashboardStatus error', error);
          return;
        });
    },
    async saveAlert(id: number): Promise<void> {
      try {
        await this.$store
          .dispatch('status/setDashboardStatus', this.status)
          .then(data => {
            if (!data) {
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus bad response', data);
              return;
            }
            this.status = { ...data };
            if (id) {
              const alertExists = this.status.alerts.findIndex(alert => alert.id === id);
              this.selectedAlert = this.status.alerts[alertExists];
            }
          })
          .catch((error: Error) => {
            // eslint-disable-next-line no-console
            console.error('setDashboardStatus error', error);
            return;
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    updateAlerts(alertObj: StatusAlert): void {
      try {
        if (!alertObj?.id) {
          alertObj.id = Math.ceil(Math.random() * 1000000);
        }
        const alertExists = this.status.alerts.findIndex(alert => alert.id === alertObj.id);
        if (alertExists !== -1) {
          // reset ID.
          alertObj.id = Math.ceil(Math.random() * 1000000);
          this.status.alerts[alertExists] = alertObj;
        } else {
          this.status.alerts.push(alertObj);
        }

        this.saveAlert(alertObj.id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    async deleteAlert(alertObj: StatusAlert): Promise<void> {
      try {
        this.status.alerts = this.status.alerts.filter((alert: StatusAlert) => {
          return alert.id !== alertObj.id;
        });
        this.saveAlert();
        this.addNew();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    async updateWhatsNew(newObj: WhatsNew): Promise<void> {
      try {
        newObj.id = Math.ceil(Math.random() * 1000000);
        this.status.whatsnew = newObj;
        await this.$store
          .dispatch('status/setDashboardStatus', this.status)
          .then(data => {
            if (!data) {
              // eslint-disable-next-line no-console
              console.log('setDashboardStatus bad response', data);
              return;
            }
            this.status = { ...data };
          })
          .catch((error: Error) => {
            // eslint-disable-next-line no-console
            console.error('setDashboardStatus error', error);
            return;
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    whatsNew(): void {
      this.showWhatsNew = true;
      this.showAlerts = false;
    },
  },
});
