
import Vue from 'vue';
import statusCheck from '../../mixins/statusCheck';
import isWithinInterval from 'date-fns/fp/isWithinInterval';
// import parseISO from 'date-fns/fp/parseISO';

export default Vue.extend({
  name: 'WhatsNewModal',
  props: ['showArticle'],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    article: any;
  } => ({
    article: null,
  }),
  mixins: [statusCheck],
  mounted() {
    this.getSCFirstArticleBySectionId();
  },
  computed: {
    articleToShow(): string {
      // console.log(this.article);
      if (!this.article) {
        return null;
      }
      return this.article;
    },
    shouldThisModalAppear(): boolean {
      // there are a few caveats to when this should appear, so a simple prop won't do
      if (!this.whatNewActive) {
        // we must prevent the modal appearing on the support page when it is inactive
        // but still show the article on the systemAdmin status alerts preview
        if (this.$route?.name?.toLowerCase() !== 'adminstatus') {
          return false;
        }
      }
      // also, we should not show the article if the user has closed it before
      const newStart = new Date(this.whatNewCurrent.start.date);
      const newEnd = new Date(this.whatNewCurrent.end.date);
      if (this.userArticleClosed && this.whatNewCurrent && this.$route?.name?.toLowerCase() !== 'adminstatus') {
        if ((isWithinInterval(this.userArticleClosed), { start: newStart, end: newEnd })) {
          return false;
        }
      }
      return this.showArticle;
    },
    userArticleClosed(): string {
      const recentStr: string | null = localStorage.getItem(`${this.$store.getters.user.email}:articleClosed`);
      return recentStr;
    },
  },
  methods: {
    closeModal(): void {
      this.$emit('close-article-modal');
      const key = `${this.$store.getters.user.email}:articleClosed`;
      // store time when user closed article
      if (this.$route?.name?.toLowerCase() !== 'adminstatus') {
        const currentdate = new Date().toString();
        localStorage.setItem(key, currentdate);
      }
    },
    getSCFirstArticleBySectionId(): void {
      this.$store
        ?.dispatch('supportCenter/getSCFirstArticleBySectionId', '8399650418967')
        .then(data => {
          this.article = data.article.body;
        })
        .catch(exp => {
          // eslint-disable-next-line no-console
          console.log(exp);
          this.$store.dispatch('showError', exp);
        });
    },
  },
});
