
import Vue from 'vue';
import { format } from 'date-fns';
import utils from '../../util';
import statusCheck from '../../mixins/statusCheck';
import { StatusAlert } from '../../types/status';

export default Vue.extend({
  name: 'statusItem',
  props: ['selectedAlert'],
  data(): {
    date: string;
    time: string;
    edit: boolean;
    saved: boolean;
    deleteDialog: boolean;
    showValidationError: boolean;
    alert: StatusAlert;
    newAlert: StatusAlert;
    rules: object;
    icons: Array<string>;
    colors: Array<string>;
  } {
    return {
      date: null,
      time: null,
      edit: true,
      saved: false,
      deleteDialog: false,
      showValidationError: false,
      icons: [
        'warning',
        'verified_user',
        'build',
        'info',
        'security',
        'label_important',
        'verified',
        'event',
        'notifications',
      ],
      colors: ['red', 'orange', 'green', 'blue', 'purple', 'black'],
      alert: {
        name: '',
        active: false,
        dismissable: false,
        location: '',
        title: '',
        message: '',
        icon: 'warning',
        color: 'red',
        start: {
          date: '',
          time: '',
          utc: '',
        },
        end: {
          date: '',
          time: '',
          utc: '',
        },
        timezone: '',
      },
      newAlert: {
        name: '',
        active: false,
        dismissable: false,
        location: '',
        title: '',
        message: '',
        icon: 'warning',
        color: 'red',
        start: {
          date: '',
          time: '',
          utc: '',
        },
        end: {
          date: '',
          time: '',
          utc: '',
        },
        timezone: '',
      },
      rules: {
        timedateCheck(incoming: string) {
          return v => {
            // if one a date or time are set, require the other.
            if (incoming.length > 0 && !(v.length > 0)) return 'Required';
            return true;
          };
        },
      },
    };
  },
  mixins: [statusCheck],
  created() {
    if (this.selectedAlert) {
      this.alert = Object.assign({}, this.selectedAlert);
      this.saved = true;
    }
  },
  watch: {
    selectedAlert: {
      handler(alert, oldAlert): void {
        // resets when a alert changes, but the component is not re-created.
        if (this.alert && alert.id === oldAlert.id) return; // don't run unless the alert has changed
        if (alert) this.alert = Object.assign({}, alert);
        else this.alert = { ...this.newAlert };
        this.saved = true;
      },
      deep: true,
    },
  },
  computed: {
    name(): string {
      return this.selectedAlert?.name || 'Status';
    },
    title(): string {
      return this.selectedAlert?.title || 'Title not set';
    },
    message(): string {
      return this.selectedAlert?.message || 'Message not set';
    },
    color(): string {
      return this.selectedAlert?.color || 'red';
    },
    icon(): string {
      return this.selectedAlert?.icon || 'warning';
    },
    loading(): string {
      return this.$store.state?.status?.loading;
    },
    location(): string {
      return utils.getTacticName(this.selectedAlert?.location) || 'Not set';
    },
    showToogle(): boolean {
      return this.selectedAlert?.dismissable || false;
    },
    startTime(): string {
      if (!this.selectedAlert?.start?.date) return 'Not Set';
      return `${this.selectedAlert?.start?.date} : ${this.selectedAlert?.start?.time}`;
    },
    endTime(): string {
      if (!this.selectedAlert?.end?.date) return 'Not Set';
      return `${this.selectedAlert?.end?.date} : ${this.selectedAlert?.end?.time}`;
    },
    timeZone(): string {
      return this.selectedAlert?.timezone ? this.selectedAlert.timezone : 'Not Set';
    },
    tacticList(): unknown {
      // creates a list useable form list of all the tactics in the util file.
      const list = utils.tacticObject;
      return Object.entries(list).reduce((all, tactic) => {
        const [value, name] = tactic;
        all.push({ name: `${name} (${value})`, value });
        return utils.sortByProperty(all, 'name');
      }, []);
    },
    buttonText(): string {
      if (this.showValidationError) return 'Missing required fields';
      return 'Save';
    },
    hasId(): boolean {
      return this.alert?.id > 0;
    },
    isActive(): boolean {
      if (!this.selectedAlert?.id) return false;
      return this.checkActiveById(this.selectedAlert?.id);
    },
  },
  methods: {
    formatDateString(date: string | object, start: boolean): string {
      if (!date) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fnsDate: any;
      try {
        if (start) this.alert.start.utc = date;
        else this.alert.end.utc = date;
        // date may come as a string or an already normalized date object
        if (typeof date === 'string') fnsDate = utils.normalizeDate(date);
        else fnsDate = date;
        return format(fnsDate, 'MMM d, yyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formattedDataFrom', date, err);
      }
      return '';
    },
    async saveAlert(): Promise<void> {
      try {
        if (this.$refs.statusForm.validate()) {
          // set users timezone
          if (this.alert?.start?.date?.length || this.alert?.end?.date?.length)
            this.alert.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          this.$emit('save-alert', this.alert);
          this.saved = true;
        } else this.showValidationError = true;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    deleteAlert(): void {
      this.deleteDialog = false;
      this.$emit('delete-alert', this.alert);
    },
    resetValidation(): void {
      this.$refs.statusForm.resetValidation();
      this.showValidationError = false;
      this.saved = false;
    },
    resetAlert(): void {
      this.saved = false;
      this.alert.active = false;
      this.alert.location = '';
      this.alert.title = '';
      this.alert.message = '';
      this.alert.icon = 'warning';
      this.alert.color = 'red';
      this.alert.start = {
        date: '',
        time: '',
        utc: '',
      };
      this.alert.end = {
        date: '',
        time: '',
        utc: '',
      };
      this.alert.timezone = '';
    },
  },
});
