
import Vue from 'vue';
import { format } from 'date-fns';
import utils from '../../util';
import statusCheck from '../../mixins/statusCheck';
import { WhatsNew } from '../../types/status';
import WhatsNewModal from './whatsNewModal.vue';

export default Vue.extend({
  name: 'statusWNItem',
  props: ['currentWhatsNew'],
  components: { WhatsNewModal },
  data(): {
    date: string;
    time: string;
    edit: boolean;
    saved: boolean;
    showValidationError: boolean;
    whatsNew: WhatsNew;
    showArticle: boolean;
    rules: object;
  } {
    return {
      date: null,
      time: null,
      edit: true,
      saved: false,
      showValidationError: false,
      showArticle: false,
      whatsNew: {
        active: false,
        start: {
          date: '',
          time: '',
          utc: '',
        },
        end: {
          date: '',
          time: '',
          utc: '',
        },
        timezone: '',
      },
      rules: {
        timedateCheck(incoming: string) {
          return v => {
            // if one a date or time are set, require the other.
            if (incoming.length > 0 && !(v.length > 0)) return 'Required';
            return true;
          };
        },
      },
    };
  },
  mixins: [statusCheck],
  created() {
    if (this.currentWhatsNew) {
      this.whatsNew = Object.assign({}, this.currentWhatsNew);
      this.saved = true;
    }
  },
  watch: {
    currentWhatsNew: {
      handler(whatsNew, oldwhatsNew): void {
        // resets when a whatsNew changes, but the component is not re-created.
        if (this.whatsNew && whatsNew.id === oldwhatsNew.id) return; // don't run unless the whatsNew has changed
        if (whatsNew) this.whatsNew = Object.assign({}, whatsNew);
        else this.whatsNew = { ...this.whatsNew };
        this.saved = true;
      },
      deep: true,
    },
  },
  computed: {
    loading(): string {
      return this.$store.state?.status?.loading;
    },
    articleLoading(): boolean {
      return this.$store.state.supportCenter.loading;
    },
    startTime(): string {
      if (!this.currentWhatsNew?.start?.date) return 'Not Set';
      return `${this.currentWhatsNew?.start?.date} : ${this.currentWhatsNew?.start?.time}`;
    },
    endTime(): string {
      if (!this.currentWhatsNew?.end?.date) return 'Not Set';
      return `${this.currentWhatsNew?.end?.date} : ${this.currentWhatsNew?.end?.time}`;
    },
    timeZone(): string {
      return this.currentWhatsNew?.timezone ? this.currentWhatsNew.timezone : 'Not Set';
    },
    buttonText(): string {
      if (this.showValidationError) return 'Missing required fields';
      return 'Save';
    },
  },
  methods: {
    formatDateString(date: string | object, start: boolean): string {
      if (!date) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fnsDate: any;
      try {
        if (start) this.whatsNew.start.utc = date;
        else this.whatsNew.end.utc = date;
        // date may come as a string or an already normalized date object
        if (typeof date === 'string') fnsDate = utils.normalizeDate(date);
        else fnsDate = date;
        return format(fnsDate, 'MMM d, yyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formattedDataFrom', date, err);
      }
      return '';
    },
    async saveWhatsNew(): Promise<void> {
      try {
        if (this.$refs.statusWNForm.validate()) {
          // set users timezone
          if (this.whatsNew?.start?.date?.length || this.whatsNew?.end?.date?.length)
            this.whatsNew.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          this.$emit('save-wn', this.whatsNew);
          this.saved = true;
        } else this.showValidationError = true;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('setDashboardStatus', err);
        return;
      }
    },
    resetValidation(): void {
      this.$refs.statusWNForm.resetValidation();
      this.showValidationError = false;
      this.saved = false;
    },
    resetWhatsNew(): void {
      this.saved = false;
      this.whatsNew.active = false;
      this.whatsNew.start = {
        date: '',
        time: '',
        utc: '',
      };
      this.whatsNew.end = {
        date: '',
        time: '',
        utc: '',
      };
      this.whatsNew.timezone = '';
    },
  },
});
